import { MagicTokenSingleton } from './token';

const baseUrl = process.env.CLIENT_API_URL || 'http://localhost:3000/api';

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function tryFetch(route, options) {
  const res = await fetch(baseUrl + route, options);
  const body = await res.json();
  if (!res.ok) {
    throw new Error(body.message);
  }
  return body;
}

export async function login(token) {
  const req = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...jsonHeaders,
    },
    body: JSON.stringify({}),
  };

  try {
    const data = await tryFetch('/auth/login/admin', req);
    return data;
  } catch (err) {
    console.error('Failed to log in', err);
    return null;
  }
}

export function getCompanies() {
  return fetch('https://dev2.backend.theviewvr.com/api/getAllCompanies')
    .then(r => r.json())
    .then(json => json.result.map(c => c.name))
    .catch(err => {
      console.error('fail:getCompanies', err);
      return null;
    });
}

export function getClients() {
  return tryFetch('/clients', {
    method: 'GET',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
  });
}

export function createClient(client) {
  return tryFetch('/clients', {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify(client),
  });
}

export async function changeClient({ id, ...client }) {
  const res = await fetch(`${baseUrl}/clients/${id}`, {
    method: 'PUT',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify(client),
  });

  if (!res.ok) {
    throw new Error((await res.json()).message);
  }

  return res;
}

export async function deleteClient(clientId) {
  const res = await fetch(`${baseUrl}/clients/${clientId}`, {
    method: 'DELETE',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
  });

  if (!res.ok) {
    throw new Error((await res.json()).message);
  }

  return res;
}

export function getAdmins() {
  return tryFetch('/admins', {
    method: 'GET',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
  });
}

export function createAdmin(admin) {
  return tryFetch('/admins', {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify(admin),
  });
}

export async function changeAdmin({ id, ...admin }) {
  const res = await fetch(`${baseUrl}/admins/${id}`, {
    method: 'PUT',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify(admin),
  });

  if (!res.ok) {
    throw new Error((await res.json()).message);
  }

  return res;
}

export async function deleteAdmin(adminId) {
  const res = await fetch(`${baseUrl}/admins/${adminId}`, {
    method: 'DELETE',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
  });

  if (!res.ok) {
    throw new Error((await res.json()).message);
  }

  return res;
}
