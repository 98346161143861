import { Magic } from 'magic-sdk';
import { MagicTokenSingleton } from './token';
import { login } from './clientApi';

const magic = new Magic(process.env.MAGIC_API_KEY || '');

export async function loginWithSession() {
  const ok = await magic.user.isLoggedIn();
  if (!ok) {
    return false;
  }

  const token = await magic.user.getIdToken();
  const user = await login(token);

  MagicTokenSingleton.I.init(magic, token);
  return user;
}

export async function loginWithEmail(email = '') {
  const token = await magic.auth.loginWithMagicLink({ email, showUI: true });
  const user = await login(token);

  MagicTokenSingleton.I.init(magic, token);
  return user;
}

export async function logout() {
  await magic.user.logout();
}
