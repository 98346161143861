import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CRUDTable, {
  Fields,
  Field,
  CreateForm,
  UpdateForm,
  DeleteForm,
} from 'react-crud-table';

import Page from '~/components/shared/Page';

import * as admins from '../../store/adminsList';
import { fetchCompanies } from '../../store/companiesList';

const SORTERS = {
  NUMBER_ASCENDING: mapper => (a, b) => (mapper(a) || 0) - (mapper(b) || 0),
  NUMBER_DESCENDING: mapper => (a, b) => (mapper(b) || 0) - (mapper(a) || 0),
  STRING_ASCENDING: mapper => (a, b) =>
    (mapper(a) || '').localeCompare(mapper(b) || ''),
  STRING_DESCENDING: mapper => (a, b) =>
    (mapper(b) || '').localeCompare(mapper(a) || ''),
};

const getSorter = data => {
  const mapper = x => x[data.field];
  let sorter = SORTERS.STRING_ASCENDING(mapper);

  if (data.field === 'id') {
    sorter =
      data.direction === 'ascending'
        ? SORTERS.NUMBER_ASCENDING(mapper)
        : SORTERS.NUMBER_DESCENDING(mapper);
  } else {
    sorter =
      data.direction === 'ascending'
        ? SORTERS.STRING_ASCENDING(mapper)
        : SORTERS.STRING_DESCENDING(mapper);
  }

  return sorter;
};

const AdminsView = () => {
  const navigate = useNavigate();

  const auth = useSelector(state => state.auth);
  const companiesList = useSelector(state => state.companiesList);
  const adminsList = useSelector(state => state.adminsList);
  const companies = [...new Set(adminsList.map(c => c.companyName))];
  const groupedAdmins = companies.reduce(
    (acc, company) => ({
      ...acc,
      [company]: adminsList.filter(c => c.companyName === company),
    }),
    {},
  );

  useEffect(() => {
    if (!auth.user || auth.user.role !== 'superadmin') {
      return navigate('/clients', { replace: true });
    }
  }, [auth, navigate]);

  const dispatch = useDispatch();

  const [companyFilter, setCompanyFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');

  const [sort, setSort] = useState({ direction: 'ascending', field: 'id' });

  const filteredGroupedAdminsList = Object.entries(groupedAdmins)
    .filter(([company]) => (companyFilter ? company === companyFilter : true))
    .map(([company, admins]) => {
      return [
        company,
        emailFilter
          ? admins.filter(a => a.email.includes(emailFilter))
          : admins,
      ];
    })
    .filter(([, admins]) => admins.length > 0)
    .flatMap(([, admins]) => admins);

  if (filteredGroupedAdminsList.length === 0 && companyFilter) {
    setCompanyFilter('');
  }

  useLayoutEffect(() => {
    dispatch(fetchCompanies());
    dispatch(admins.fetchAdmins());
  }, []);

  function createAdmin(admin) {
    return dispatch(admins.createAdmin(admin)).catch(({ message }) =>
      alert(message),
    );
  }

  function changeAdmin(admin) {
    return dispatch(admins.changeAdmin(admin))
      .then(() => alert('Администратор был обновлен'))
      .catch(({ message }) => alert(message));
  }

  function deleteAdmin(adminId) {
    return dispatch(admins.deleteAdmin(adminId)).catch(({ message }) =>
      alert(message),
    );
  }

  if (!companiesList.length) return null;

  return (
    <Page title="Admins">
      <div className="mx-auto container" style={{ maxWidth: '1400px' }}>
        <div className="mb-8">
          <h3>Фильтры: </h3>
          <div className="ml-3">
            <label>Компания: </label>
            <select
              className="focus:outline-none rounded-lg px-3 py-1"
              onChange={e => setCompanyFilter(e.target.value)}
              value={companyFilter}
            >
              <option value="">Все компании</option>
              {companies.map(company => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>
          <div className="ml-3 mt-2">
            <label>Email: </label>
            <input
              className="focus:outline-none rounded-lg px-3 py-1"
              onChange={e => setEmailFilter(e.target.value)}
              value={emailFilter}
            />
          </div>
        </div>

        <CRUDTable
          onChange={e => setSort(e.sort)}
          caption="Администраторы"
          items={Array.from(filteredGroupedAdminsList).sort(getSorter(sort))}
        >
          <Fields>
            <Field name="id" label="Id" hideInCreateForm hideInUpdateForm />
            <Field name="companyName" label="Компания" />
            <Field name="email" label="Email" />
            <Field
              name="role"
              label="Роль"
              render={({ field, form }) => {
                return (
                  <select
                    name="role"
                    value={field.value}
                    onChange={e => {
                      form.setFieldValue('role', e.target.value);
                    }}
                  >
                    <option value="admin">admin</option>
                    <option value="superadmin">Superadmin</option>
                  </select>
                );
              }}
            />
          </Fields>

          <CreateForm
            title="Создание администратора"
            message="Создайте нового администратора!"
            trigger="Создать администратора"
            onSubmit={admin => createAdmin(admin).then(() => admin)}
            submitText="Создать администратора"
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = 'Не указан email';
              }

              if (
                values.companyName &&
                !companiesList.includes(values.companyName)
              ) {
                errors.companyName = 'Такой компании не существует!';
              }

              return errors;
            }}
          />

          <UpdateForm
            title="Процесс обновления администратора"
            message="Обновить администратора"
            trigger="Обновить"
            onSubmit={admin => changeAdmin(admin).then(() => admin)}
            submitText="Обновить"
            validate={values => {
              const errors = {};

              if (!values.id) {
                errors.id = 'Не указан id';
              }

              if (
                values.companyName &&
                !companiesList.includes(values.companyName)
              ) {
                errors.companyName = 'Такой компании не существует!';
              }

              if (!values.email) {
                errors.email = 'Не указан email';
              }

              return errors;
            }}
          />

          <DeleteForm
            title="Удаление"
            message="Вы уверены, что хотите удалить данного администратора?"
            trigger="Удалить"
            onSubmit={admin => deleteAdmin(admin.id).then(() => admin)}
            submitText="Удалить"
            validate={values => {
              const errors = {};
              if (!values.id) {
                errors.id = 'Please, provide id';
              }
              return errors;
            }}
          />
        </CRUDTable>
      </div>
    </Page>
  );
};

export default AdminsView;
