import React, { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CRUDTable, {
  Fields,
  Field,
  CreateForm,
  UpdateForm,
  DeleteForm,
} from 'react-crud-table';

import Page from '~/components/shared/Page';

import * as clients from '../../store/clientsList';
import { fetchCompanies } from '../../store/companiesList';

const SORTERS = {
  NUMBER_ASCENDING: mapper => (a, b) => (mapper(a) || 0) - (mapper(b) || 0),
  NUMBER_DESCENDING: mapper => (a, b) => (mapper(b) || 0) - (mapper(a) || 0),
  STRING_ASCENDING: mapper => (a, b) =>
    (mapper(a) || '').localeCompare(mapper(b) || ''),
  STRING_DESCENDING: mapper => (a, b) =>
    (mapper(b) || '').localeCompare(mapper(a) || ''),
};

const getSorter = data => {
  const mapper = x => x[data.field];
  let sorter = SORTERS.STRING_ASCENDING(mapper);

  if (data.field === 'id') {
    sorter =
      data.direction === 'ascending'
        ? SORTERS.NUMBER_ASCENDING(mapper)
        : SORTERS.NUMBER_DESCENDING(mapper);
  } else {
    sorter =
      data.direction === 'ascending'
        ? SORTERS.STRING_ASCENDING(mapper)
        : SORTERS.STRING_DESCENDING(mapper);
  }

  return sorter;
};

const AdminsView = () => {
  const companiesList = useSelector(state => state.companiesList);
  const clientsList = useSelector(state => state.clientsList);
  const companies = [...new Set(clientsList.map(c => c.companyName))];
  const groupedClients = companies.reduce(
    (acc, company) => ({
      ...acc,
      [company]: clientsList.filter(c => c.companyName === company),
    }),
    {},
  );

  const dispatch = useDispatch();

  const [companyFilter, setCompanyFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');

  const [sort, setSort] = useState({ direction: 'ascending', field: 'id' });

  const filteredGroupedClientsList = Object.entries(groupedClients)
    .filter(([company]) => (companyFilter ? company === companyFilter : true))
    .map(([company, clients]) => {
      return [
        company,
        emailFilter
          ? clients.filter(c => c.email.includes(emailFilter))
          : clients,
      ];
    })
    .filter(([, clients]) => clients.length > 0)
    .flatMap(([, clients]) => clients);

  if (filteredGroupedClientsList.length === 0 && companyFilter) {
    setCompanyFilter('');
  }

  useLayoutEffect(() => {
    dispatch(fetchCompanies());
    dispatch(clients.fetchClients());
  }, []);

  function createClient(client) {
    return dispatch(clients.createClient(client)).catch(({ message }) =>
      alert(message),
    );
  }

  function changeClient(client) {
    return dispatch(clients.changeClient(client))
      .then(() => alert('Пользователь был обновлен'))
      .catch(({ message }) => alert(message));
  }

  function deleteClient(clientId) {
    return dispatch(clients.deleteClient(clientId)).catch(({ message }) =>
      alert(message),
    );
  }

  if (!companiesList.length) return null;

  return (
    <Page title="Clients">
      <div className="mx-auto container" style={{ maxWidth: '1400px' }}>
        <div className="mb-8">
          <h3>Фильтры: </h3>
          <div className="ml-3">
            <label>Компания: </label>
            <select
              className="focus:outline-none rounded-lg px-3 py-1"
              onChange={e => setCompanyFilter(e.target.value)}
              value={companyFilter}
            >
              <option value="">Все компании</option>
              {companies.map(company => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>
          <div className="ml-3 mt-2">
            <label>Email: </label>
            <input
              className="focus:outline-none rounded-lg px-3 py-1"
              onChange={e => setEmailFilter(e.target.value)}
              value={emailFilter}
            />
          </div>
        </div>

        <CRUDTable
          onChange={e => setSort(e.sort)}
          caption="Клиенты"
          items={Array.from(filteredGroupedClientsList).sort(getSorter(sort))}
          // fetchItems={payload => {
          //   return Promise.resolve(Array.from(filteredGroupedClientsList).sort(getSorter(payload.sort)))
          // }}
        >
          <Fields>
            <Field name="id" label="Id" hideInCreateForm hideInUpdateForm />
            <Field name="companyName" label="Компания" />
            <Field name="email" label="Email" />
          </Fields>

          <CreateForm
            title="Создание клиента"
            message="Создайте нового клиента!"
            trigger="Создать клиента"
            onSubmit={client => createClient(client).then(() => client)}
            submitText="Создать клиента"
            validate={values => {
              const errors = {};

              if (!companiesList.includes(values.companyName)) {
                errors.companyName = 'Такой компании не существует!';
              }

              if (!values.companyName) {
                errors.companyName = 'Не указано имя компании';
              }

              if (!values.email) {
                errors.email = 'Не указан email';
              }

              return errors;
            }}
          />

          <UpdateForm
            title="Процесс обновления клиента"
            message="Обновить пользователя"
            trigger="Обновить"
            onSubmit={client => changeClient(client).then(() => client)}
            submitText="Обновить"
            validate={values => {
              const errors = {};

              if (!values.id) {
                errors.id = 'Не указан id';
              }

              if (!companiesList.includes(values.companyName)) {
                errors.companyName = 'Такой компании не существует!';
              }

              if (!values.companyName) {
                errors.companyName = 'Не указано имя компании';
              }

              if (!values.email) {
                errors.email = 'Не указан email';
              }

              return errors;
            }}
          />

          <DeleteForm
            title="Удаление"
            message="Вы уверены, что хотите удалить данного клиента?"
            trigger="Удалить"
            onSubmit={client => deleteClient(client.id).then(() => client)}
            submitText="Удалить"
            validate={values => {
              const errors = {};
              if (!values.id) {
                errors.id = 'Please, provide id';
              }
              return errors;
            }}
          />
        </CRUDTable>
      </div>
    </Page>
  );
};

export default AdminsView;
