import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import theme from '~/theme';
import routes from '~/routes';
import GlobalStyles from '~/components/shared/GlobalStyles';
import MagicAuth from '~/components/shared/MagicAuth';
import 'react-perfect-scrollbar/dist/css/styles.css';

const App = () => {
  const routing = useRoutes(routes());
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <MagicAuth>{routing}</MagicAuth>
    </ThemeProvider>
  );
};

export default App;
